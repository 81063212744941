<template>
  <el-form ref="form" :model="goodsStockForm" label-width="85px" :rules="rules">
    <div v-loading="loading" class="wrap">
      <!-- 顶部按钮 -->
      <div class="btnTOP">
        <el-button
          type="primary"
          size="mini"
          :disabled="disabled"
          @click="submitForm(true)"
          >保存
        </el-button>
        <el-button size="mini" @click="getQuit">退出</el-button>
      </div>
      <!-- 表格商品信息卡片 -->
      <cardTitleCom card-title="商品信息" class="cardMessage">
        <template slot="cardContent">
          <!-- 商品信息按钮和表格整体盒子 -->
          <div class="goodsMessage">
            <el-table
              ref="dragTable"
              :data="goodsStockForm.details"
              @cell-mouse-enter="cellMouseEnter"
              @cell-mouse-leave="cellMouseLeave"
              border
            >
              <el-table-column
                align="center"
                :label="tableCellLabel"
                width="80"
              >
                <template v-slot="scope">
                  <i
                    v-if="scope.row.hoverRow"
                    :disabled="disabled"
                    @click="row('push', scope.$index)"
                    class="el-icon-circle-plus operatePush"
                  ></i>
                  <i
                    v-if="scope.row.hoverRow"
                    :disabled="disabled"
                    @click="row('del', scope.$index)"
                    class="el-icon-remove operateDel"
                  ></i>
                  <div v-if="!scope.row.hoverRow">{{ scope.$index + 1 }}</div>
                </template>
              </el-table-column>
              <el-table-column width="180" align="center" prop="goodsNo">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>商品编码</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'details.' + scope.$index + '.goodsNo'"
                    :rules="rules[`details.goodsNo`]"
                  >
                    <el-input
                      v-model="scope.row.goodsNo"
                      :disabled="disabled"
                      @keyup.enter.native="
                        keyup('goodsNo', $event, scope.$index)
                      "
                    >
                      <i
                        slot="suffix"
                        class="el-icon-more more"
                        @click="selectGoods"
                      />
                    </el-input>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="商品名称"
                align="center"
                prop="goodsName"
                width="130"
              />
              <el-table-column
                label="商品类别"
                align="center"
                prop="categoryName"
                width="130"
              />
              <el-table-column align="center" prop="storeId" width="150">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>仓库</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'details.' + scope.$index + '.storeId'"
                    :rules="rules[`details.storeId`]"
                  >
                    <SelectRemote
                      v-model="scope.row.storeId"
                      style="width: 120px"
                      :option="
                        $select({
                          key: 'listStore',
                          option: { option: { disabled: disabled } }
                        }).option
                      "
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="规格"
                align="center"
                prop="categoryName"
                width="120"
              />
              <el-table-column
                label="商品条码"
                align="center"
                prop="barcode"
                width="160"
              />
              <el-table-column align="center" prop="unitId" width="120">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>单位</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'details.' + scope.$index + '.unitId'"
                    :rules="rules[`details.unitId`]"
                  >
                    <el-select
                      v-model="scope.row.unitId"
                      :disabled="disabled"
                      filterable
                      @change="unitPriceChange($event, scope.row)"
                    >
                      <el-option
                        v-for="item in scope.row.units"
                        :key="item.unitId"
                        :label="item.unitName"
                        :value="item.unitId"
                      />
                    </el-select>
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" width="120">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>数量</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'details.' + scope.$index + '.unitFirstQty'"
                    :rules="rules[`details.unitFirstQty`]"
                  >
                    <el-input
                      v-model="scope.row.unitFirstQty"
                      @keyup.native="onkeyupUnitQty(scope.row)"
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column align="center" width="150" prop="unitFirstPrice">
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>成本</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'details.' + scope.$index + '.unitFirstPrice'"
                    :rules="rules[`details.unitFirstPrice`]"
                  >
                    <el-input
                      v-model="scope.row.unitFirstPrice"
                      @keyup.native="onkeyupUnitQty(scope.row)"
                    />
                  </el-form-item>
                </template>
              </el-table-column>
              <el-table-column
                label="期初总金额"
                align="center"
                width="150"
                prop="unitMoney"
              >
                <template slot="header">
                  <i style="color: #ff4949">* </i>
                  <span>期初总金额</span>
                </template>
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'details.' + scope.$index + '.unitMoney'"
                    :rules="rules[`details.unitMoney`]"
                  >
                    <el-input
                      v-model="scope.row.unitMoney"
                      @keyup.native="onkeyupUnitQty(scope.row, 'money')"
                    />
                  </el-form-item>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </template>
      </cardTitleCom>
    </div>
    <!-- 选择商品信息框 -->
    <SelectGoods
      :OpenGoods.sync="OpenGoods"
      :query-code="queryCode"
      @getGoodS="getGoodS"
    />
  </el-form>
</template>

<script>
import cardTitleCom from '@/views/components/cardTitleCom' // 卡片标题
import SelectGoods from '@/views/components/selectGoodsUp' // 选择商品
import { listGoods } from '@/api/goods/goods' // 商品列表
import { allStoreList } from '@/api/system/store' // 仓库
import { goodsStockBatchSaveAPI } from '@/api/system/initialData/goodsStock' // 期初建账
import SelectRemote from '@/components/tablePage/select/select-remote/index.vue'

export default {
  name: 'GoodsStockDetail',
  components: {
    cardTitleCom,
    SelectGoods,
    SelectRemote
  },
  data () {
    return {
      tableCellLabel: '序号',
      // 仓库数据
      storeData: [],
      // 表单禁用
      disabled: false,
      // 选择商品信息开关
      OpenGoods: false,
      // 传送商品参数
      queryCode: undefined,
      // 遮罩层
      loading: false,
      // 旧的表单
      oldForm: {},
      // 期初录入表单
      goodsStockForm: {
        // 单据表格-订单明细
        details: [{}]
      },
      // 表单校验
      rules: {
        'details.goodsNo': [
          {
            required: true,
            message: '请选择商品',
            trigger: ['blur', 'change']
          }
        ],
        'details.storeId': [
          {
            required: true,
            message: '仓库不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'details.unitId': [
          {
            required: true,
            message: '单位不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'details.unitFirstQty': [
          {
            required: true,
            message: '数量不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'details.unitFirstPrice': [
          {
            required: true,
            message: '成本不能为空',
            trigger: ['blur', 'change']
          }
        ],
        'details.unitMoney': [
          {
            required: true,
            message: '期初金额不能为空',
            trigger: ['blur', 'change']
          }
        ]
      }
    }
  },
  created () {
    this.getInitializeData()
  },
  methods: {
    //表格行hover时,显示操作加减号
    cellMouseEnter (row) {
      let eleIndex = this.goodsStockForm.details.indexOf(row)
      for (let index = 0; index < this.goodsStockForm.details.length; index++) {
        const element = this.goodsStockForm.details[index]
        if (eleIndex == index) {
          element['hoverRow'] = true
        } else {
          element['hoverRow'] = false
        }
      }
      this.tableCellLabel = '操作'
    },
    //表格行离开hover时,不显示操作加减号,显示序号
    cellMouseLeave () {
      // 移除hover的事件
      for (let index = 0; index < this.goodsStockForm.details.length; index++) {
        const element = this.goodsStockForm.details[index]
        element['hoverRow'] = false
      }
      this.tableCellLabel = '序号'
    },
    /** 提交按钮 */
    async submitForm (isBool) {
      this.$refs['form'].validate(async valid => {
        if (valid) {
          await goodsStockBatchSaveAPI(this.goodsStockForm.details)
          this.$modal.msgSuccess('新增成功')
          this.getQuit()
        }
      })
    },
    // 基本单位进价
    unitPriceChange (value, row) {
      row.units.forEach(v => {
        if (v.unitId === value) {
          this.$set(row, 'unitPrice', v.unitPrice)
          this.$set(row, 'unitFirstPrice', v.unitPrice)
        }
      })
    },
    // 封装计算 unitMoney 的函数
    calculateUnitMoney (row) {
      if (row.unitFirstQty && row.unitFirstPrice) {
        const unitFirstQty = Number(row.unitFirstQty)
        const unitFirstPrice = Number(row.unitFirstPrice)
        const unitMoney = unitFirstQty * unitFirstPrice
        console.log('unitMoney', unitMoney)
        return this.formatNumber(unitMoney)
      } else {
        return 0
      }
    },
    // 修改 onkeyupUnitQty 函数
    onkeyupUnitQty (row, name) {
      if (name && name == 'money') {
        if (row.unitFirstQty && row.unitMoney) {
          const unitMoney = Number(row.unitMoney)
          const unitFirstQty = Number(row.unitFirstQty)
          const unitFirstPrice = unitMoney / unitFirstQty
          this.$set(row, 'unitFirstPrice', this.formatNumber(unitFirstPrice))
        } else {
          this.$set(row, 'unitFirstPrice', 0)
        }
      } else {
        const unitMoney = this.calculateUnitMoney(row)
        this.$set(row, 'unitMoney', unitMoney)
      }
    },
    // 初始化下拉框
    async getInitializeData () {
      // 获取仓库详情
      const res1 = await allStoreList()
      this.storeData = res1.data
    },
    // 退出
    getQuit () {
      this.$store.state.tagsView.visitedViews.splice(
        this.$store.state.tagsView.visitedViews.findIndex(
          item => item.path === this.$route.path
        ),
        1
      )
      this.$destroy()
      this.$router.push('/goods/initialData/goodsStock')
    },

    // 选择商品方法
    selectGoods () {
      this.OpenGoods = true
    },
    // 选中的商品
    getGoodS (value) {
      if (value.length == 0) return
      // 去重
      value = value.filter(item => {
        return this.goodsStockForm.details.every(v => v.goodsId != item.goodsId)
      })
      if (value.length == 0) return
      value.forEach(v => {
        this.$set(v, 'unitPrice', v.purPrice)
        this.$set(v, 'unitFirstPrice', v.purPrice)
      })
      this.goodsStockForm.details.push(...value)
      this.goodsStockForm.details = this.goodsStockForm.details.filter(
        item =>
          (!(JSON.stringify(item) === '{}') && !(item.goodsId === undefined)) ||
          !(item.goodsId === undefined)
      )
    },
    // 表格单元格回车事件
    keyup (name, event, index) {
      // 判断是否输入编码或条码
      if (this.goodsStockForm.details[`${index}`][`${name}`]) {
        listGoods({
          [name]: this.goodsStockForm.details[`${index}`][`${name}`]
        }).then(response => {
          // 判断是否精准匹配 匹配直接赋值 不匹配弹出商品框
          if (response.total === 1) {
            event.target.blur()
            // 去重
            const isRepeat = this.goodsStockForm.details.every(
              item => item.goodsId != response.rows[0].goodsId
            )
            if (!isRepeat) {
              this.$message.error('当前表格已有此商品!')
              return
            }
            // 原始赋值
            this.$set(response.rows[0], 'unitPrice', response.rows[0].purPrice)
            this.$set(
              response.rows[0],
              'unitFirstPrice',
              response.rows[0].purPrice
            )
            // 使用vue的$set方法更新数组数据,使视图可编辑
            this.$set(this.goodsStockForm.details, index, response.rows[0])
          } else {
            this.queryCode = this.goodsStockForm.details[`${index}`][`${name}`]

            this.OpenGoods = true
          }
        })
      } else {
        // 没输入直接弹出商品信息框
        event.target.blur()
        this.OpenGoods = true
      }
    },
    // 表格增加/减少一行方法
    async row (name, index) {
      if (name === 'push') {
        this.goodsStockForm.details.splice(index + 1, 0, {})
      } else {
        if (this.goodsStockForm.details.length <= 1) {
        this.goodsStockForm.details[{}]
        return
        }
        this.goodsStockForm.details.splice(index, 1)
      }
    }
  }
}
</script>

<style scoped lang="scss">
//整体盒子
.wrap {
  width: 100%;
  padding: 6px 10px;
  background-color: #eaeaea;
  // 顶部按钮
  .btnTOP {
    text-align: right;
    padding: 0 10px 6px 0;
  }
  .goodsMessage {
    padding: 6px 10px;
    ::v-deep .el-form-item__content {
      margin-left: 0 !important;
    }
    ::v-deep .el-form-item__error {
      position: static;
    }
  }
}
::v-deep .el-form-item {
  margin: 0;
  padding: 0;
}
</style>
